<div *ngIf="!progress" class="hotel-container relative" [ngClass]="{'yatra' : hotelOrLuxurystaycation}">
    <div class="listing-tabs-link" id="hotel-search-row"></div>
    <div class="hotel-page-heading absolute container">
        <h1 *ngIf="hotelOrLuxurystaycation===0" class="sp-heading gold mb-20 text-center" [ngClass]="{'yatra' : hotelOrLuxurystaycation}">Get the best of Global Luxury with AURUM Rewards Points</h1>
        <h1 *ngIf="hotelOrLuxurystaycation===1" class="sp-heading gold mb-20 text-center" [ngClass]="{'yatra' : hotelOrLuxurystaycation}">Redeem AURUM Reward Points on a comfortable stay</h1>   
    </div>
    <div class="hotel-search-bar container mar-top-2 absolute">
        <div class="row hotel-search-row pad-top-10 pad-bot-10 pad-left-10 pad-right-10 d-flex">
            <mat-radio-group class='align-self-center' [(ngModel)]="hotelOrLuxurystaycation" color="accent">
                <mat-radio-button class="example-margin" (change)="radioChange($event)" [value]="1">
                    <span [class]="hotelOrLuxurystaycation ? 'highlighted' : ''">
                        Yatra
                    </span>
                    <span [class]="hotelOrLuxurystaycation ? 'sp-badge active' : 'sp-badge'">Domestic</span>
                </mat-radio-button>
                <mat-radio-button class="example-margin" (change)="radioChange($event)" [value]="0">
                    <span [class]="hotelOrLuxurystaycation ? '' : 'highlighted'">
                        Mr & Mrs Smith
                    </span>
                    <span [class]="hotelOrLuxurystaycation ? 'sp-badge' : 'sp-badge highlighted active'">Luxury holidays worldwide</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="row hotel-search-row pad-top-0 pad-bot-10 pad-left-10 pad-right-10">
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-md-12 col-lg-4 home-input-fields form-field relative pad-bot-15 pad-right-15" dropdown #dropdown="bs-dropdown">
                        <div class="form-field__control" dropdownToggle>
                            <input  id="exampleFieldOrigin" type="text" autocomplete="off" class="form-field__input arrows text-truncate" [ngClass]="{'error' : destinationError}" [formControl]='whereInput' [(ngModel)]="whereInput.value" placeholder=" "
                             type="text" #myInput (click)="myInput.select(); openDropDownPopOpen(); inputToShow = 'destinationDrop'; focusouthere();" (input)="dropdown.show();"/>
                            <label for="exampleFieldOrigin" class="form-field__label">Select your destination</label>
                            <div class="form-field__bar"></div>
                        </div>
                        <div  class="dropdown-menu option-contain w100" *dropdownMenu>
                            <div class="dropdown location-dropdown" *ngIf="!isLoading && !destination && !(whereListDestination.length || whereListHotels.length || whereListCountries.length || whereListLocalities.length)">
                                <div class="w100">
                                    <div class="option-item ellipsis disabled">
                                        <div class="option-item-inner">
                                            No results found
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown location-dropdown" *ngIf="isLoading && !destination">
                                <div class="w100">
                                    <div class="option-item ellipsis disabled">
                                        <div class="option-item-inner">
                                            Loading...
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown location-dropdown">
                                <div class='w100'>
                                    <div *ngIf="(whereListCountries | filter: whereInput.value : 'name') as result">
                                        <div class="block loc-results category" *ngIf="result.length">Countries</div>
                                        <hr>
                                        <div class="option-item ellipsis" *ngFor="let where of result">
                                            <div class="option-item-inner"
                                                (mousedown)="setSelectedHotel(where, false, false, true, false)">
                                                {{where.name}}
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div *ngIf="(whereListDestination | filter: whereInput.value : 'name') as result">
                                        <div class="block loc-results category" *ngIf="result.length">Cities / Localities</div>
                                        <hr>
                                        <div class="option-item ellipsis" *ngFor="let where of result">
                                            <div class="option-item-inner"
                                                (mousedown)="setSelectedHotel(where, false, false, false, false)">
                                                {{where.name}}
                                            </div>
                                        </div>
                                        <div *ngIf="result.length">
                                            <div *ngIf="(whereListLocalities | filter: whereInput.value : 'name') as result">
                                                <div class="option-item ellipsis" *ngFor="let where of result">
                                                    <div class="option-item-inner"
                                                        (mousedown)="setSelectedHotel(where, false, false, false, true)">
                                                        {{where.name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="(whereListDestination | filter: whereInput.value : 'name') as result">
                                        <div *ngIf="!result.length">
                                            <div *ngIf="(whereListLocalities | filter: whereInput.value : 'name') as resultL">
                                                <div class="block loc-results category" *ngIf="resultL.length">Cities / Localities</div>
                                                <hr>
                                                <div class="option-item ellipsis" *ngFor="let where of resultL">
                                                    <div class="option-item-inner"
                                                        (mousedown)="setSelectedHotel(where, false, false, false, true)">
                                                        {{where.name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div *ngIf="(whereListHotels | filter: whereInput.value : 'name') as result">
                                        <div class="block loc-results category" *ngIf="result.length">Hotels</div>
                                        <hr>
                                        <div class="option-item ellipsis" *ngFor="let where of result">
                                            <div class="option-item-inner"
                                                (mousedown)="setSelectedHotel(where, true, false, false, false)">
                                                {{where.name}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="destinationError">
                            <span class="fnt-10 error-msg">{{destinationError}}</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 home-input-fields pad-bot-15 pad-right-15 pad-left-15" (mousedown)="closedateRangeDropdown()">
                        <div
                            class='activity-select w100 d-flex align-items-center form-field relative'>
                            <div class="d-none d-sm-block w100 form-field__control datepicker">
                                <input #desktopDatepicker id="exampleFieldDDate" type="text" class="form-field__input arrows relative" 
                                name="daterange" readonly placeholder=" " ngxDaterangepickerMd autoApply='false' [addHeight]="70"
                                showRangeLabelOnInput="true" disableSameDate='true' linkedCalendars='true' [ngClass]="{'error' : checkinoutError}"
                                [minDate]="minCheckinoutDate" (datesUpdated)="datesUpdated($event)">
                                <label for="exampleFieldDDate" class="form-field__label">Check-In → Check-Out</label>
                                <div class="form-field__bar"></div>
                            </div>
                            <div class='w100 ft-wt-400 d-block d-sm-none form-field__control datepicker'
                                (click)="opendateRangeDropdown(); openDropDownPopOpen(); inputToShow = 'dateDrop'; openMobileDatePicker()">
                                <input id="exampleFieldDDate1" type="text" class="form-field__input arrows relative" readonly
                                [ngClass]="{'error' : checkinoutError}" placeholder=" " [value]="checkInOutDates ? (checkInOutDates.startDate | date:'d MMM, y') +'  →  '+ (checkInOutDates.endDate | date:'d MMM, y') : ''">
                                <label for="exampleFieldDDate1" class="form-field__label">Check-In → Check-Out</label>
                                <div class="form-field__bar"></div>
                            </div>
                        </div>
                        <div *ngIf="checkinoutError">
                            <span class="fnt-10 error-msg">{{checkinoutError}}</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 home-input-fields pad-bot-15 pad-right-15 pad-left-15">
                        <custom-dropdown [preset]="true"
                            class="d-none d-sm-block" [addDynamicContent]="true" [rooms]="rooms" style="width: 100%;"
                            (onRoomsChange)="onRoomsChange($event)">
                        </custom-dropdown>
                        <div class="activity-select w100 d-flex align-items-center form-field relative d-block d-sm-none">
                            <div class='w100 ft-wt-400 form-field__control'
                                (click)="inputToShow = 'roomDrop';openDropDownPopOpen();">
                                <input id="exampleFieldRoom" type="text" class="form-field__input arrows relative" readonly placeholder=" "
                                 [value]="showText" [ngClass]="{'error' : roomselectorError}">
                                <label for="exampleFieldRoom" class="form-field__label">Guests, Rooms</label>
                                <div class="form-field__bar"></div>
                            </div>
                        </div>
                        <div *ngIf="roomselectorError">
                            <span class="fnt-10 error-msg">{{roomselectorError}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-2 d-flex home-input-fields pad-bot-10 pad-left-15 mob-mar-top-40">
                <div class="col-12 align-self-center home-enquire-button no-margin no-padding main-button">
                    <button (click)="searchHotel()" mat-button class="w100">Search Hotels
                        <span class="mar-left-10 loader btn-loader-default" *ngIf="clickedSearch"></span>
                    </button>
                </div>
            </div>
        </div>
        <section class="all-mob-dropdowns d-lg-none d-xl-none" [ngClass]="{'active' : dropDownPopOpen}">
            <div class="close-bar flex align-center">
                <div class="close-icon-contain mar-top-15" (click)="dropDownPopOpen = false;removeModalOpen()">
                    <span class="icon-cancel fnt-24"></span>
                </div>
            </div>
            <div id="origin-drop" *ngIf="inputToShow == 'destinationDrop'">
                <div class="flex txt-box justify-center">
                    <div class="img-box mar-right-5">
                        <span class="icon-group fnt-18"></span>
                    </div>
                    <div class="fnt-16 f500">Select your destination</div>
                </div>
                <div class="input-contain mar-top-20">
                    <input type="text" class="form-control fnt-14" [formControl]='whereInput'
                    [(ngModel)]="whereInput.value" placeholder="Select your destination">
                </div>
                <div class="option-contain pad-top-10">
                    <div class="dropdown location-dropdown" *ngIf="!isLoading && !destination && !(whereListDestination.length || whereListHotels.length || whereListCountries.length || whereListLocalities.length)">
                        <div class="show dropdown-menu w100">
                            <div class="option-item ellipsis">
                                <div class="option-item-inner">
                                    No results found
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown location-dropdown" *ngIf="isLoading && !destination">
                        <div class="show dropdown-menu w100">
                            <div class="option-item ellipsis">
                                <div class="option-item-inner">
                                    Loading...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(whereListCountries | filter: whereInput.value : 'name') as result">
                        <div class="block loc-results category" *ngIf="result.length">Countries</div>
                        <hr>
                        <div class="option-item ellipsis" *ngFor="let where of result">
                            <div class="option-item-inner"
                                (mousedown)="setSelectedHotel(where, false, true, true, false)">
                                {{where.name}}
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div *ngIf="(whereListDestination | filter: whereInput.value : 'name') as result">
                        <div class="block loc-results category" *ngIf="result.length">Cities / Localities</div>
                        <hr>
                        <div class="option-item ellipsis" *ngFor="let where of result">
                            <div class="option-item-inner"
                                (mousedown)="setSelectedHotel(where, false, true, false, false)">
                                {{where.name}}
                            </div>
                        </div>
                        <div *ngIf="result.length">
                            <div *ngIf="(whereListLocalities | filter: whereInput.value : 'name') as result">
                                <div class="option-item ellipsis" *ngFor="let where of result">
                                    <div class="option-item-inner"
                                        (mousedown)="setSelectedHotel(where, false, true, false, true)">
                                        {{where.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(whereListDestination | filter: whereInput.value : 'name') as result">
                        <div *ngIf="!result.length">
                            <div *ngIf="(whereListLocalities | filter: whereInput.value : 'name') as result">
                                <div class="block loc-results category" *ngIf="result.length">Cities / Localities</div>
                                <hr>
                                <div class="option-item ellipsis" *ngFor="let where of result">
                                    <div class="option-item-inner"
                                        (mousedown)="setSelectedHotel(where, false, true, false, true)">
                                        {{where.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div *ngIf="(whereListHotels | filter: whereInput.value : 'name') as result">
                        <div class="block loc-results category" *ngIf="result.length">Hotels</div>
                        <hr>
                        <div class="option-item ellipsis" *ngFor="let where of result">
                            <div class="option-item-inner"
                                (mousedown)="setSelectedHotel(where, true, true, false, false)">
                                {{where.name}}
                            </div>
                        </div>
                    </div>
                    <div class="map-done w100 d-block d-sm-none">
                        <button class="w100 height-45" (click)="closeDropDownPopOpen()"
                            mat-button>Done</button>
                    </div>
                </div>
            </div>
            <div id="origin-drop" *ngIf="inputToShow == 'dateDrop'">
                <div class="flex txt-box justify-center">
                    <div class="img-box mar-right-5">
                        <span class="icon-group-1 fnt-18"></span>
                    </div>
                    <div class="fnt-16 f500">Select dates</div>
                </div>
                <div class="input-contain mar-top-20">
                    <div
                        [class]="isdateRangeDropdownOpen == true ? 'activity-select fnt-14 w100 d-flex align-items-center bg' : ' activity-select fnt-14 w100 d-flex align-items-center bg'">
                        <input #mobileDatepicker
                            (click)="opendateRangeDropdown(); openDropDownPopOpen(); inputToShow = 'dateDrop'"
                            (focusout)="closedateRangeDropdown()" type="text" name="daterange" readonly
                            placeholder="Check-In → Check-Out" ngxDaterangepickerMd alwaysShowCalendars='true'
                            autoApply='false' [addHeight]="170" class='form-control hotel-mobile-date w100 ft-wt-400 bg' disableSameDate='true' linkedCalendars='true' showDropdowns='true'
                            [minDate]="minCheckinoutDate" (datesUpdated)="datesUpdated($event);">
                    </div>
                </div>
            </div>
            <div id="origin-drop" *ngIf="inputToShow == 'roomDrop'">
                <div class="flex txt-box justify-center">
                    <div class="img-box">
                        <span class="icon-group-size fnt-18"></span>
                    </div>
                    <div class="fnt-16 f500">Choose number of guests & rooms</div>
                </div>
                <custom-dropdown [rooms]="rooms" style="width: 100%;" [preset]="true" [disableDoneButton]="dropDownPopOpen"
                    (onMobileDropdownClose)="onMobileDropdownClose($event)" (onRoomsChange)="onRoomsChange($event)" [mobileNewWindow]="true">
                </custom-dropdown>
            </div>
        </section>
    </div>
</div>
<div *ngIf="!progress && hidePageBottom" class="secondary-background d-none d-sm-block">
    <div class="row sp-section  dark3">
        <div class="container secondary-background">
            <h1 class="sp-heading gold center mb-60"> Why you should book with AURUM Rewards</h1>
            <div class='row my-5'>
                <div class='col-md-4 col-lg-4 col-12 text-left'>
                    <img src="assets/sbic-images/suitcase-shield.png" class="img-fluid" style="height:65px;">
                    <h6 class="my-3 ft-wt-600 gold">Get great deals</h6>
                    <p class="f300 fnt-16">From boutique hotels to global brands, discover thousands of rooms all around
                        the world.</p>
                </div>
                <div class='col-md-4 col-lg-4 col-12 text-left'>
                    <img src="assets/sbic-images/loan.png" class="img-fluid" style="height:65px;">
                    <h6 class="my-3 ft-wt-600 gold">Get special perks</h6>
                    <p class="f300 fnt-16">Get insider access - from savings on room rates to complimentary benefits.</p>
                </div>
                <div class='col-md-4 col-lg-4 col-12 text-left'>
                    <img src="assets/sbic-images/charges.png" class="img-fluid" style="height:65px;">
                    <h6 class="my-3 ft-wt-600 gold">Pay with points</h6>
                    <p class="f300 fnt-16">Use your reward points to book rooms, and maximize your savings when you
                        travel.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row d-none d-sm-block sp-section dark4 center">
        <div class="container secondary-background">
            <div class="row">
                <div class="col-12">
                    <h1 class="sp-heading gold center mb-20">Need Inspiration?</h1>
                    <p class="text-center mb-60 ">View our hand-picked hotel destinations</p>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-4 col-12 text-center d-flex justify-content-center my-lg-0 my-md-0 my-2'>
                    <div class="tab-link">
                        <div class="card pointer shadow-sm relative" (click)="bottomCardBasedRoute('Dubai')">
                            <div class="overflow-hidden image-container">
                                <img src="assets/hotels/Dubai.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-start">
                                <div class="card-text text-left fnt-16 f300">
                                    <span class="f400">Dubai,</span> United Arab
                                    Emirates <a class="sp-link arrow-link"></a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-md-4 col-12 text-center d-flex justify-content-center my-lg-0 my-md-0 my-2'>
                    <div class="tab-link">
                        <div class="card pointer shadow-sm relative" (click)="bottomCardBasedRoute('Singapore')">
                            <div class="overflow-hidden image-container">
                                <img src="assets/hotels/singapore.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-start">
                                <div class="card-text text-left fnt-16 f300"><span class="f400">Singapore</span>
                                    <a class="sp-link arrow-link"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-md-4 col-12 text-center d-flex justify-content-center my-lg-0 my-md-0 my-2'>
                    <div class="tab-link">
                        <div class="card pointer shadow-sm relative" (click)="bottomCardBasedRoute('London')">
                            <div class="overflow-hidden image-container">
                                <img src="assets/hotels/London.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-start">
                                <div class="card-text text-left fnt-16 f300 text-left"> <span class="f400">London,</span>
                                    United Kingdom
                                    <a class="sp-link arrow-link"></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-lg-5 my-md-5 my-0 ">
                <div class='col-md-4 col-12 text-center d-flex justify-content-center my-lg-0 my-md-0 my-2'>
                    <div class="tab-link">
                        <div class="card pointer shadow-sm relative" (click)="bottomCardBasedRoute('Paris')">
                            <div class="overflow-hidden image-container">
                                <img src="assets/hotels/paris.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-start">
                                <div class="card-text text-left fnt-16 f300"><span class="f400">Paris,</span> France
                                    <a class="sp-link arrow-link"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-md-4 col-12 text-center d-flex justify-content-center my-lg-0 my-md-0 my-2'>
                    <div class="tab-link">
                        <div class="card pointer shadow-sm relative" (click)="bottomCardBasedRoute('Bangkok')">
                            <div class="overflow-hidden image-container">
                                <img src="assets/hotels/bangkok.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-start">
                                <div class="card-text text-left fnt-16 f300"><span class="f400">Bangkok,</span> Thailand 
                                    <a class="sp-link arrow-link"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-md-4 col-12 text-center d-flex justify-content-center my-lg-0 my-md-0 my-2'>
                    <div class="tab-link">
                        <div class="card pointer shadow-sm relative" (click)="bottomCardBasedRoute('Amsterdam')">
                            <div class="overflow-hidden image-container">
                                <img src="assets/hotels/amsterdam.png" class="card-img-top" alt="...">
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-start">
                                <div class="card-text text-left fnt-16 f300"><span class="f400">Amsterdam,</span> Netherlands
                                <a class="sp-link arrow-link"></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="progress"></app-loader>
