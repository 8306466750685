import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ToursActivitiesService } from 'src/app/tours-activities/services/tours-activities/tours-activities.service'
import { UntypedFormControl } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: 'app-tours-home',
  templateUrl: './tours-home.component.html',
  styleUrls: ['./tours-home.component.scss']
})
export class ToursHomeComponent implements OnInit {
  isCityDropdownOpen: boolean = false;
  isActivityDropdownOpen: boolean = false;
  citiesList = [];
  isLoading: boolean = false;
  selectedCity: any;
  activityList = [];
  selectedActivity: any;
  cityInput = new UntypedFormControl();
  activityInput = new UntypedFormControl();
  inputToShow: string;
  dropDownPopOpen: boolean;
  progress: boolean = true;
  exchangeRates = [];
  filterCityList: boolean = false;
  filterActivityList: boolean = false;
  clickedSearch: boolean = false;
  @ViewChild('cityDropdown', { static: false }) cityDropdown: ElementRef<HTMLElement>;
  @ViewChild('activityDropdown', { static: false }) activityDropdown: ElementRef<HTMLElement>;

  constructor(
    private toursActivitiesService: ToursActivitiesService,
    private renderer: Renderer2,
    private router: Router,
    public data: DataService,

  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getCities();
    this.getCurrencyExchangeRates();
    this.activityInput.valueChanges.subscribe((value) => {
      if (this.selectedActivity && this.selectedActivity.name !== value) {
        this.selectedActivity = undefined;
      }
    });
  }


  search() {
    if (this.verifyForm()) {
      if (this.selectedActivity) {
        const path = this.selectedActivity.name.split(' ').join('-').split('/').join('-') + '-' + this.selectedActivity.id
        this.clickedSearch = true;
        this.router.navigate(['tours-and-activities/activity/' + path]);
        // this.clickedSearch = false;
      } else {
        this.clickedSearch = true;

        this.router.navigate(['tours-and-activities/listing/' + this.selectedCity.name]);
        // this.clickedSearch = false;
      }
    }
  }

  closeCityDrop() {
    this.isCityDropdownOpen = false;
    this.cityInput.setValue(this.selectedCity.name);
    this.filterCityList = false;
  }

  openCityDrop() {
    if (window.innerWidth > 992) {
      this.isCityDropdownOpen = true;
    }
    this.cityfocusouthere();
    this.dropDownPopOpen = true;
    this.inputToShow = 'cityDrop'
    this.addModalOpen();
  }

  closeActivityDrop() {
    this.isActivityDropdownOpen = false;
  }

  openActivityDrop() {
    if (window.innerWidth > 992) {
      this.isActivityDropdownOpen = true;
    }
    this.activityfocusouthere();
    this.dropDownPopOpen = true;
    this.inputToShow = 'activityDrop';
  }

  getListByCity() {
    this.isLoading = true;
    this.resetActivity();
    this.toursActivitiesService.getProductListByCity(this.selectedCity.code)
      .then((res: any) => {
        this.activityList = Array.isArray(res.data.listings) ? res.data.listings : [];
        this.progress = false;
        this.isLoading = false;
      });
  }

  getCities() {
    this.toursActivitiesService.getCities().then((res: any) => {
      this.citiesList = res.data.items;
      this.selectedCity = this.citiesList[0];
      this.sortCitiesAlphabetically();
      this.cityInput.setValue(this.selectedCity.name)
      this.getListByCity();
    });
  }

  sortCitiesAlphabetically() {
    this.citiesList = this.citiesList.sort(function (a, b) {
      if (a.code.toLowerCase() < b.code.toLowerCase()) { return -1; }
      if (a.code.toLowerCase() > b.code.toLowerCase()) { return 1; }
      return 0;
    });
  }

  setSelectedCity(city) {
    if (city.code !== this.selectedCity.code) {
      this.selectedCity = city;
      this.getListByCity();
    }
    this.cityInput.setValue(city.name);
    this.isCityDropdownOpen = false;
    this.filterCityList = false;
  }

  setSelectedActivity(activity) {
    this.selectedActivity = activity;
    this.activityInput.setValue(activity.name);
    this.filterActivityList = false;
  }

  addModalOpen() {
    if (window.innerWidth < 992) {
      this.renderer.addClass(document.body, 'modal-open');
    }
  }

  removeModalOpen() {
    this.renderer.removeClass(document.body, 'modal-open');
    if (window.innerWidth < 992) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  resetActivity() {
    this.activityInput.reset();
    this.activityList = [];
    this.selectedActivity;
  }

  getCurrencyExchangeRates() {
    this.toursActivitiesService.getCurrencyExchangeRates().then((res: any) => {
      this.exchangeRates = res.exchange_rates;
    });
  }


  verifyForm() {
    if (!this.selectedCity) {
      this.data.triggerToastr('error',"Select destination.")
      return false;
    }
    return true;
  }

  cityfocusouthere() {
    if (window.innerWidth < 992) {
      this.cityDropdown.nativeElement.blur();
    }
  }

  activityfocusouthere() {
    if (window.innerWidth < 992) {
      this.activityDropdown.nativeElement.blur();
    }
  }

  cityInputChange() {
    this.filterCityList = true;
  }

  activityInputChange() {
    this.filterActivityList = true;
  }

}
